<script lang="ts" setup>
import {
  HeartOutlined,
  MoreOutlined,
  ShoppingOutlined,
  UserOutlined,
} from '@ant-design/icons-vue'
import { storeToRefs } from 'pinia'
import { useAclStore } from '../../store/acl'
import type { OutletType } from '../../models/outlet'
import { OutletModel } from '../../models/outlet'
import { dataFetchList } from '~/realm/data/list'
import type { ProductFavoriteType } from '~/models/productFavorite'
import {
  ProductFavoriteModel,
} from '~/models/productFavorite'
import { useAgentBeautician } from '~/composable/agent'
const selectedKeys = ref([])
const showSideMenu = ref(false)
const $acl = useAclStore()
const { cart } = storeToRefs($acl)
const { isOutlet, isAgent }  = useAgentBeautician()
const isAgentOrOutlet = computed(() => isAgent.value || isOutlet.value)
interface DataItem {
  title: string
  link?: string
  description?: string
  icon?: any
}
const menus: DataItem[] = [
  {
    title: 'Home',
    link: '/',
    // icon: HomeOutlined,
  },
  {
    title: 'e-Catalogue',
    link: 'https://1drv.ms/b/s!AggTRIndADTAguoaAMdMmqUg8itQiQ'
  },
  {
    title: 'Sales',
    link: '/sales',
    // icon: GiftOutlined,
  },
  {
    title: 'iluvmyface',
    link: '/c/iluvmyface',
  },
  ...(isAgentOrOutlet? 
  [{
    title: 'Skin Therapies',
    link: '/skinTherapies',
  }]: []),
  {
    title: 'Spa & wellness',
    link: '/spaWellness',
  },
  {
    title: 'All products',
    link: '/products',
  },
  {
    title: 'Checkout',
    // icon: ShoppingOutlined,
    link: '/cart',
  },
]

// console.log('$acl.currentUser', $acl.currentUser.accessToken)
const outletModel = new OutletModel()
const $route = useRoute()
const router = useRouter()
const loading = ref(false)
const searchText = ref($route.query.q as string)
const showSearch = ref(false)
const totalCartItem = ref<number>(0)
const favoriteCount = ref<number>(0)
const favoriteProducts = ref<ProductFavoriteType[]>()
let a = ref(0)
const outlet = ref<OutletType>()

const fetchOutlet = async () => {
  const resOutlet = await dataFetchList<OutletType>(outletModel, {
    filters: {
      $or: [
        // own outlet
        { managerId: $acl.currentUser?.id },
        // assigned / referred outlet
        ...($acl.currentUser.customData?.outletId
          ? [
              { _id: $acl.currentUser.customData?.outletId },
            ]
          : []),
      ],
    },
    page: 1,
    limit: 99,
    sorts: [
      {
        field: '_id',
        dir: 'asc',
      },
    ],
  })

  // console.log(
  //   'fetchoutlet',
  //   {
  //     managerId: $acl.currentUser?.id,
  //   },
  //   resOutlet,
  // )

  outlet.value = resOutlet.list.length > 0 ? resOutlet.list[0] : undefined
}

onMounted(async () => {
  loading.value = true
  try {
    await Promise.all([getFavoriteCount(), fetchOutlet()])
  }
  finally {
    loading.value = false
  }
})

async function getFavoriteCount() {
  await dataFetchList<ProductFavoriteType[]>(new ProductFavoriteModel(), {
    page: 1,
    limit: 999,
    filters: { _ownerId: $acl.currentUser.id },
    sorts: [{ field: 'createdAt', dir: 'asc' }],
  }).then((res) => {
    if (!res) {
      console.error('product is not in database')
    }
    else {
      console.log(res.count)
      favoriteCount.value = res.count
    }
  })
}

const onSearch = async () => {
  const search = searchText.value
  if (search) {
    router.push({
      path: '/search',
      query: {
        q: search,
      },
    })
  }
}

watchEffect(() => {
  if (cart.value) {
    let cartItem = cart.value.items || []
    let sum = 0
    for (let i = 0; i < cartItem.length; i++) {
      sum += +cartItem[i].quantity
    }
    totalCartItem.value = sum
  }
})
</script>

<template>
  <div class="">
    <a-drawer
      :width="300"
      class="mobileSidebar"
      title="Cel-Elements"
      placement="left"
      :visible="showSideMenu"
      @close="() => (showSideMenu = false)"
    >
      <template #extra></template>
      <!-- <div class="searchBar">
        <a-input-search v-model:value="searchText" placeholder="search" :loading="loading" />
      </div> -->
      <a-list item-layout="horizontal" :data-source="menus">
        <template #renderItem="{ item }">
          <a-list-item>
            <a-list-item-meta :description="item.description">
              <template #title>
                <a :href="item.link">{{ item.title }}</a>
              </template>
              <template #avatar>
                <component :is="item?.icon" v-if="item.icon"></component>
              </template>
            </a-list-item-meta>
          </a-list-item>
        </template>
      </a-list>
    </a-drawer>
  </div>

  <a-layout-header theme="light" class="hidePrint md:mb-2 relative bg-[#8DB600]">
    <div class="absolute top-6 md:top-0 md:top-0 right-0 text-right">
      <div class="mt-4 mr-2">
        <a-button
          type="link"
          class="m-0 py-0 h-5 px-2 text-inherit	"
          @click="() => router.push('/favorites')"
        >
          <a-badge
            :count="favoriteCount"
            :number-style="{
              backgroundColor: '#fff',
              color: '#1890ff',
              boxShadow: '0 0 0 1px #d9d9d9 inset',
            }"
          >
            <HeartOutlined class="text-xl	text-black" />
          </a-badge>
        </a-button>
        <a-button
          type="link"
          class="m-0 py-0 h-5 px-2"
          @click="() => router.push('/cart')"
        >
          <a-badge
            :count="totalCartItem"
            :number-style="{
              backgroundColor: '#fff',
              color: '#1890ff',
              boxShadow: '0 0 0 1px #d9d9d9 inset',
            }"
          >
            <ShoppingOutlined class="text-xl text-black" />
          </a-badge>
        </a-button>

        <a-button
          type="link"
          class="m-0 py-0 h-5 px-2"
          @click="() => router.push('/account')"
        >
          <UserOutlined class="text-xl text-black" />
        </a-button>

        <!-- mobile menu -->
        <a-button
          type="link"
          class="ml-1 py-1 h-5 float-right block md:hidden"
          @click="() => (showSideMenu = !showSideMenu)"
        >
          <MoreOutlined class="text-xl text-black" />
        </a-button>
      </div>

      <div class="hidden md:block">
        <router-link class="link" to="/tipsAdvice">Tips &amp; advice</router-link>
        <router-link class="link" to="/about">About us</router-link>
      </div>
    </div>

    <div class="flex md:flex-none">
      <div
        v-if="!isOutlet"
        class="logo text-center cursor-pointer w-full mt-2 ml-[-40px] md:float-left  md:w-auto mix-blend-multiply"
        @click="() => router.push('/')"
      />
      <img v-else class="logo w-full mt-2 ml-[-40px] md:float-left  md:w-auto" :srcset="imageSrcset(outlet?.photoURL)"  @click="() => router.push('/')"/>
      <div v-if="outlet?.bannerPhotoURL" class="md:float-left w-full md:w-auto pt-2">
        <img
          loading="lazy"
          :srcset="imageSrcset(outlet?.bannerPhotoURL)"
          class="h-16 object-cover w-full md:w-auto"
        />
      </div>
    </div>

    <div class="h-3 hidden md:block"></div>
    <!-- spacer -->

    <div>
      <div class="capitalize mt-0 hidden md:inline-block">
        <router-link class="link" to="/c/ce">CE Products</router-link>
        <!--<a class="link" href="https://1drv.ms/b/s!AggTRIndADTAguoaAMdMmqUg8itQiQ" target="_blank">e-Catalogue</a>-->
        <router-link class="link" to="/catalogue">e-Catalogue</router-link>
        <router-link class="link" to="/c/iluvmyface">iluvmyface</router-link>
        <router-link class="link" to="/c/skinTherapies" v-if="isAgentOrOutlet">Skin Therapies</router-link>
        <router-link class="link" to="/products">All products</router-link>
        <!-- <a-menu
          v-model:selectedKeys="selectedKeys"
          mode="horizontal"
          class="capitalize hidden md:block bg-none float-left"
          :style="{ margin: '8px 0', lineHeight: '31px' }"
        >
          <a-menu-item key="sales">Sales</a-menu-item>
          <a-menu-item key="skincare">Skin care</a-menu-item>
          <a-menu-item key="therapies">Therapies</a-menu-item>
          <a-menu-item key="spa">Spa &amp; welness</a-menu-item>
          <a-menu-item key="myface">ILoveMyFace</a-menu-item>
        </a-menu> -->
      </div>

      <div class="float-right hidden md:inline-block">
        Welcome
        <span v-if="$acl.currentUser">{{ $acl.currentUserName }}</span>
      </div>
    </div>

    <div class="searchBar py-2  mt-1 md:mt-2">
      <a-input-search
        size="large"
        v-model:value="searchText"
        placeholder="search"
        :loading="loading"
        @search="onSearch"
      />
    </div>
  </a-layout-header>
</template>

<style scoped>

.mobileSidebar a {
  color: #777777;
}

a {
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
}

a:hover {
  color: #777777;
}
.ant-btn-link {
  @apply text-current opacity-70;
}

.ant-btn-link:hover {
  opacity: 1;
}
.logo {
  width: 200px;
  height: 70px;
  background-image: url('/img/CelElements.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.nav {
  top: 50%;
  position: relative;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
</style>
